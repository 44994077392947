"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Roles = void 0;
var Roles;
(function (Roles) {
    Roles["CREATOR"] = "CREATOR";
    Roles["ADMIN"] = "ADMIN";
    Roles["MEMBER"] = "MEMBER";
    Roles["CUSTOM"] = "CUSTOM";
})(Roles || (exports.Roles = Roles = {}));
