import { cn } from "@/lib/utils";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import React, { ElementType, ComponentPropsWithoutRef, Fragment, forwardRef } from "react";

type IconOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children?: React.ReactNode;
  className?: string | undefined;

  icon?: React.ReactNode;

  iconWrapperClassName?: string | undefined;
  containerClassName?: string | undefined;
} & VariantProps<typeof iconVariants>;

export type IconProps<E extends ElementType> = IconOwnProps<E> &
  Omit<ComponentPropsWithoutRef<E>, keyof IconOwnProps>;

export const iconVariants = cva(["flex justify-center items-center icon-btn"], {
  variants: {
    variant: {
      default: "bg-container field-border",
      group: "bg-container field-border",
      success: "background-success text-white",
      danger: "background-danger text-white",
      unstyled: ""
    },
    size: {
      small: "w-[65px] h-[42px]",
      landscape: "w-[65px] h-[42px]",
      medium: "w-[30px] h-[30px]",
      default: "w-[50px] h-[50px]",
      24: "w-[24px] h-[24px]",
      20: "w-[20px] h-[20px]",
      36: "w-[36px] h-[36px]",
      40: "w-[40px] h-[40px]",
      48: "w-[48px] h-[48px]",
      58: "w-[58px] h-[58px]",
      16: "w-[16px] h-[16px]"
    },
    radius: {
      full: "rounded-full"
    }
  },
  defaultVariants: {
    variant: "default",
    size: "small",
    radius: "full"
  }
});

const IconContainer = forwardRef(
  <E extends ElementType = "button">(
    {
      as,
      children,
      variant,
      size,
      icon,
      className,
      iconWrapperClassName,
      ...props
    }: IconProps<E> & React.ComponentPropsWithoutRef<E>,
    ref: React.Ref<any>
  ) => {
    const Element = as || "button";

    return (
      <Element className={cn(iconVariants({ variant, size }), className)} ref={ref} {...props}>
        <Fragment>
          {icon ? <div className={cn("inline-block", iconWrapperClassName)}>{icon}</div> : null}
          {children}
        </Fragment>
      </Element>
    );
  }
);

IconContainer.displayName = "IconContainer";

export default IconContainer;
