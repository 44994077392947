"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.InputTool = exports.NavBars = exports.UserHiddenLinks = exports.UserHiddenFields = void 0;
var UserHiddenFields;
(function (UserHiddenFields) {
    UserHiddenFields["email"] = "email";
    UserHiddenFields["phoneCode"] = "phoneCode";
    UserHiddenFields["phoneNumber"] = "phoneNumber";
    UserHiddenFields["pronouns"] = "pronouns";
    UserHiddenFields["birthday"] = "birthday";
})(UserHiddenFields || (exports.UserHiddenFields = UserHiddenFields = {}));
var UserHiddenLinks;
(function (UserHiddenLinks) {
    UserHiddenLinks["linkedin"] = "linkedin";
    UserHiddenLinks["twitter"] = "twitter";
    UserHiddenLinks["instagram"] = "instagram";
    UserHiddenLinks["tiktok"] = "tiktok";
    UserHiddenLinks["youtube"] = "youtube";
    UserHiddenLinks["website"] = "website";
})(UserHiddenLinks || (exports.UserHiddenLinks = UserHiddenLinks = {}));
var NavBars;
(function (NavBars) {
    NavBars["Chat"] = "Chat";
    NavBars["Browse_Channels"] = "Browse Channels";
    NavBars["Membership_Directory"] = "Membership Directory";
    NavBars["Membership_Management"] = "Membership Management";
    NavBars["Roles"] = "Roles";
    NavBars["Account_Settings"] = "Account Settings";
})(NavBars || (exports.NavBars = NavBars = {}));
var InputTool;
(function (InputTool) {
    InputTool["QWERTY"] = "QWERTY";
    InputTool["AZERTY"] = "AZERTY";
})(InputTool || (exports.InputTool = InputTool = {}));
