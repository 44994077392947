"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.JoinedVia = exports.MemberStatus = exports.MemberRole = void 0;
var MemberRole;
(function (MemberRole) {
    MemberRole["ADMIN"] = "ADMIN";
    MemberRole["MODERATOR"] = "MODERATOR";
    MemberRole["GUEST"] = "GUEST";
    MemberRole["BOT"] = "BOT";
})(MemberRole || (exports.MemberRole = MemberRole = {}));
var MemberStatus;
(function (MemberStatus) {
    MemberStatus["PENDING"] = "PENDING";
    MemberStatus["APPROVED"] = "APPROVED";
    MemberStatus["REJECTED"] = "REJECTED";
})(MemberStatus || (exports.MemberStatus = MemberStatus = {}));
var JoinedVia;
(function (JoinedVia) {
    JoinedVia["Explore"] = "Explore";
    JoinedVia["Event"] = "Event";
    JoinedVia["Referral"] = "Referral";
})(JoinedVia || (exports.JoinedVia = JoinedVia = {}));
