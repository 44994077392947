"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Permission = void 0;
var Permission;
(function (Permission) {
    Permission["EDIT_COMMUNITY_PAGE"] = "EDIT_COMMUNITY_PAGE";
    Permission["INVITE_MEMBER"] = "INVITE_MEMBER";
    Permission["APPROVE_MEMBER"] = "APPROVE_MEMBER";
    Permission["VIEW_CHANNEL"] = "VIEW_CHANNEL";
    Permission["CREATE_CHANNEL"] = "CREATE_CHANNEL";
    Permission["ADD_CHANNEL_MEMBER"] = "ADD_CHANNEL_MEMBER";
    Permission["REMOVE_CHANNEL_MEMBER"] = "REMOVE_CHANNEL_MEMBER";
    Permission["EDIT_CHANNEL"] = "EDIT_CHANNEL";
    Permission["VIEW_CATEGORY"] = "VIEW_CATEGORY";
    Permission["CREATE_CATEGORY"] = "CREATE_CATEGORY";
    Permission["EDIT_CATEGORY"] = "EDIT_CATEGORY";
    Permission["VIEW_MEMBER_ROLE"] = "VIEW_MEMBER_ROLE";
    Permission["EDIT_MEMBER_ROLE"] = "EDIT_MEMBER_ROLE";
    Permission["VIEW_ROLE_PERMISSION"] = "VIEW_ROLE_PERMISSION";
    Permission["CREATE_ROLE_PERMISSION"] = "CREATE_ROLE_PERMISSION";
    Permission["EDIT_ROLE_PERMISSION"] = "EDIT_ROLE_PERMISSION";
})(Permission || (exports.Permission = Permission = {}));
