"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChannelMode = exports.ChannelType = void 0;
var ChannelType;
(function (ChannelType) {
    ChannelType["TEXT"] = "TEXT";
    ChannelType["AUDIO"] = "AUDIO";
    ChannelType["VIDEO"] = "VIDEO";
    ChannelType["THREAD"] = "THREAD";
})(ChannelType || (exports.ChannelType = ChannelType = {}));
var ChannelMode;
(function (ChannelMode) {
    ChannelMode["PUBLIC"] = "PUBLIC";
    ChannelMode["PRIVATE"] = "PRIVATE";
})(ChannelMode || (exports.ChannelMode = ChannelMode = {}));
