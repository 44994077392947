"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tables = void 0;
exports.Tables = {
    Session: "session",
    Member: "member",
    Server: "server",
    Channel: "channel",
    Conversation: "conversation",
    ConversationMember: "conversation_member"
};
