import { AccessMode, ThemeModeType } from "@key.ai/enum";
import { OnboardingQuestion } from "./entities";
import { SetupType } from "./community";

export interface OnboardingForm {
  themeMode: ThemeModeType;
  fontStyle: string;
  name: string;
  slug: string;

  logoUrl: string;
  logoLiveUrl: string;
  logoBlob: File | null;

  // coverUrl: string;
  // coverLiveUrl: string;
  // coverBlob: File | null;

  accessMode: AccessMode;
  setupType: SetupType;
}

export interface InviteQuestion extends OnboardingQuestion {
  answer?: string;
  date?: string;
  checked?: string[];
  selected?: string;
}

export interface InviteForm {
  questions: Partial<InviteQuestion>[];
}
export interface ProfileFormType {
  firstName: string;
  lastName: string;
  // username: string;
  phoneCode: string;
  phoneNumber: string;
  phone: string;

  country: string;
  // countryId: number | null;

  // state: string;
  // stateId: number | null;

  // city: string | null;
  // cityId: number | null;

  bio: string;

  imageUrl: string;
  imageBlob: File | null;
  imageLiveUrl: string | null;

  // coverUrl: string;
  // coverBlob: File | null;
  // coverLiveUrl: string | null;

  // agree: boolean;

  timezone: string;
  location: null | { address: string; placeID: string; json?: google.maps.GeocoderResult };
}

export interface Timezone {
  zoneName: string;
  gmtOffset: number;
  gmtOffsetName: string;
  abbreviation: string;
  tzName: string;
}

export enum LoginMethods {
  KeyAi = "key.ai",
  KeyApi = "key.api",
  KeyAccount = "key.account",
  KeyToken = "key.token",
  Google = "google",
  Apple = "apple"
}

export enum AvatarType {
  USER = "USER",
  COMMUNITY = "COMMUNITY"
}

export interface KickStartStep {
  value: string;
  title: string;
  isCompleted: boolean;
  child?: KickStartSubStep[];
}

export interface KickStartSubStep {
  value: string;
  title: string;
  isCompleted: boolean;
}
export interface Account {
  id: string;
  email: string;
}

export enum SortBy {
  ASC = "ASC",
  DESC = "DESC"
}

export enum FormikState {
  Submitted = "submitted"
}

export enum ChatType {
  Channels = "channels",
  Conversations = "conversations"
}

export interface OnboardingFormInterface {
  questions: OnboardingQuestion[];
  deleteIds: string[];
}

export enum Envs {
  Development = "development",
  Production = "production",
  Staging = "staging"
}
