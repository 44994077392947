import convert from "heic-decode";

export const convertHeicToJpeg = async (file: File) => {
  try {
    const buffer = new Uint8Array(await file.arrayBuffer());
    const { width, height, data } = await convert({ buffer });

    const canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    const clamped = new Uint8ClampedArray(data);
    const img = new ImageData(clamped, width, height);
    ctx?.putImageData(img, 0, 0);

    const blob: Blob | null = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/jpeg", 1)
    );
    canvas.remove();

    if (blob) {
      const fileName = file.name.replace(/\.[^/.]+$/, ".jpg");
      return new File([blob], fileName, { type: "image/jpeg" });
    }
  } catch (error) {
    console.log("Convert Err", error);
    return null;
  }
};
