"use client";
import React, { Fragment, useCallback } from "react";
import {
  DropEvent,
  DropzoneOptions,
  DropzoneState,
  FileRejection,
  useDropzone
} from "react-dropzone";
import { useGlobalLoaderModal } from "@/stores/use-global-loader-store";
import { convertHeicToJpeg } from "@/lib/image";

type DropzoneProps = {
  children?: ((props: DropzoneState) => React.JSX.Element) | React.ReactNode;
  className?: string;
} & DropzoneOptions;

export const Dropzone = ({ children, className, onDrop, ...props }: DropzoneProps) => {
  const { onOpen, onClose } = useGlobalLoaderModal();

  const onDropHandler = useCallback(
    async (accepted: any[], rejections: FileRejection[], event: DropEvent) => {
      const files = [];
      onOpen();

      for (const file of accepted) {
        if (file.type === "image/heic") {
          const _file = await convertHeicToJpeg(file);
          if (_file) files.push(_file);
        } else {
          files.push(file);
        }
      }

      onDrop?.(files, rejections, event);
      onClose();
    },
    [onDrop, onOpen, onClose]
  );

  const { getRootProps, getInputProps, ...others } = useDropzone({
    ...props,
    onDrop: onDropHandler
  });

  return (
    <Fragment>
      <div {...getRootProps({ className })}>
        <input {...getInputProps()} />
        {typeof children === "function"
          ? children({ getRootProps, getInputProps, ...others })
          : children}
      </div>
    </Fragment>
  );
};
