"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gender = exports.Provider = void 0;
var Provider;
(function (Provider) {
    Provider["EMAIL"] = "EMAIL";
    Provider["LINKEDIN"] = "LINKEDIN";
    Provider["APPLE"] = "APPLE";
    Provider["GOOGLE"] = "GOOGLE";
})(Provider || (exports.Provider = Provider = {}));
var Gender;
(function (Gender) {
    Gender["MALE"] = "MALE";
    Gender["FEMALE"] = "FEMALE";
    Gender["TRANSGENDER"] = "TRANSGENDER";
})(Gender || (exports.Gender = Gender = {}));
