import * as React from "react";
import { cn } from "@/lib/utils";
import { FormikErrors } from "formik";
import Label from "./label";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  leftSection?: React.ReactNode;
  leftSectionBgClassName?: string;
  rightSectionClassName?: string;
  rightSection?: any;
  label?: React.ReactNode;
  labelClassName?: string;
  className?: string;
  inputClassName?: string;
  warpperClassName?: string;
  type?: string;
  variant?: "small" | "medium" | "large" | "medium-s" | "default" | "huge";
  error?: boolean;
  errorOutline?: boolean;
  readOnly?: boolean;
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  unstyled?: boolean;
  fullWidth?: boolean;
  errClassName?: string;
  bottomChildren?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      leftSection,
      rightSection,
      type,
      variant = "medium",
      error = false,
      errorMessage = "",
      errorOutline = false,
      leftSectionBgClassName = "",
      rightSectionClassName = "",
      label,
      labelClassName,
      unstyled = false,
      inputClassName,
      warpperClassName,
      fullWidth,
      errClassName,
      bottomChildren,
      readOnly = false,
      ...props
    },
    ref
  ) => {
    return (
      <div
        className={cn(
          fullWidth ? "w-full" : "w-auto",
          {
            "border-none shadow-none bg-transparent": unstyled
          },
          warpperClassName
        )}
      >
        {label && (
          <div className={cn(" mb-s", labelClassName)}>
            <Label>{label}</Label>
          </div>
        )}
        <div
          className={cn(
            `flex items-center  rounded-lg   w-full ${readOnly ? "bg-gray-50" : "bg-container"} shadow-none field-border`,
            className,
            {
              "border-red-500 border-1 border-solid": errorOutline
            }, // Apply red border color if error is true,
            {
              "!border-none shadow-none bg-transparent": unstyled
            }
          )}
        >
          {leftSection && (
            <div
              className={cn(
                "rounded-s-xs text-color-primary p-l h-full text-body1 select-none flex-shrink-0",
                leftSectionBgClassName
              )}
            >
              {leftSection}
            </div>
          )}
          <input
            type={type}
            className={cn(
              "bg-transparent outline-none placeholder-color-tertiary flex-grow w-full",
              {
                "py-s px-s": variant === "small",
                "py-m px-m": variant === "medium",
                "py-l": variant === "large",
                "py-l px-l text-lg": variant === "huge",
                "py-m-nudge": variant === "medium-s",
                "py-m px-l": variant === "default"
              },
              inputClassName,
              "py-0"
            )}
            ref={ref}
            readOnly={readOnly}
            {...props}
          />
          {rightSection && (
            <div
              className={cn(
                "rounded-s-xs p-4 text-color-primary h-full select-none flex-shrink-0",
                rightSectionClassName
              )}
            >
              {/* <div className="bg-accent-100 rounded-e-small  text-color-primary p-l h-full text-body1 select-none"> */}
              {rightSection}
            </div>
          )}
        </div>
        {/* Render error message if error is true */}
        {error && errorMessage && (
          // <p className="text-red-500 text-xs pl-s-nudge">{errorMessage}</p>
          <span className={cn("text-red-500 text-xs mt-1 mx-l", errClassName)}>
            {typeof errorMessage === "object" ? JSON.stringify(errorMessage) : errorMessage}
          </span>
        )}
        {bottomChildren}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
