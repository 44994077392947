"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.BlotTypes = void 0;
var BlotTypes;
(function (BlotTypes) {
    BlotTypes["Mention"] = "mention";
    BlotTypes["Channel"] = "channel";
    BlotTypes["Emoji"] = "emoji";
    BlotTypes["User"] = "user";
})(BlotTypes || (exports.BlotTypes = BlotTypes = {}));
