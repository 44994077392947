export const getFacebookUrl = (value: string) => {
  return /^https?:\/\//.test(value) ? value : `https://www.facebook.com/${value}`;
};

export const getInstagramUrl = (value: string) => {
  return /^https?:\/\//.test(value) ? value : `https://www.instagram.com/${value}`;
};

export const getTwitterUrl = (value: string) => {
  return /^https?:\/\//.test(value) ? value : `https://twitter.com/${value}`;
};

export const getLinkedinUrl = (value: string) => {
  return /^https?:\/\//.test(value) ? value : `https://www.linkedin.com/in/${value}`;
};

export const AuthRoutes = {
  LOGIN: "/auth",
  LOGOUT: "/auth/logout"
};

export const Links = {
  TermsOfServices: "https://key.ai/terms-of-service/",
  PrivacyPolicy: "https://www.key.ai/privacy-policy/"
};

export const SocialLinks = {
  LinkedIn: "https://www.linkedin.com/company/key-ai/",
  Instagram: "https://www.instagram.com/unlockwithkeyai/",
  Twitter: "https://x.com/unlockwithkeyai",
  Youtube: "https://www.youtube.com/@unlockwithkeyai"
};

export const isValidUrl = (url?: string) => {
  if (!url) return false;
  return /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(:\d+)?(\/[^\s]*)?$/.test(url);
};

export const ignoreDomains = ["app", "auth", "auth-dev", "app-dev"];
