"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationType = exports.NotificationFor = void 0;
var NotificationFor;
(function (NotificationFor) {
    NotificationFor["ALL"] = "ALL";
    NotificationFor["INTERNAL"] = "INTERNAL";
    NotificationFor["ADMIN"] = "ADMIN";
    NotificationFor["MEMBER"] = "MEMBER";
    NotificationFor["USER"] = "USER";
})(NotificationFor || (exports.NotificationFor = NotificationFor = {}));
var NotificationType;
(function (NotificationType) {
    NotificationType["GENERAL"] = "GENERAL";
    NotificationType["MENTION"] = "MENTION";
    NotificationType["THREAD"] = "THREAD";
    NotificationType["REACTION"] = "REACTION";
    NotificationType["EVENT"] = "EVENT";
    NotificationType["CALENDAR"] = "CALENDAR";
    NotificationType["MESSAGE"] = "MESSAGE";
})(NotificationType || (exports.NotificationType = NotificationType = {}));
