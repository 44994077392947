import { cn } from "@/lib/utils";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import React, {
  ElementType,
  forwardRef,
  ForwardedRef,
  ReactNode,
  ComponentPropsWithoutRef,
  ComponentPropsWithRef
} from "react";
import { Loader, LoaderProps } from "@/components/ui/loader";

export const button = cva(["relative flex items-center justify-center text-nowrap"], {
  variants: {
    variant: {
      primary: "button-primary",
      ternary: "bg-container",
      secondary: "button-secondary hover:button-primary",
      success: "background-success text-white",
      danger: "background-danger text-white",
      unstyled: "",
      text: "py-10 px-5"
    },
    size: {
      default: "h-10 px-4 py-2",
      sm: "h-9 px-3",
      md: "h-10 px-5",
      lg: "h-11 px-8",
      icon: "h-10 w-10",
      auto: "h-auto w-auto"
    },
    radius: {
      sm: "rounded-sm",
      md: "rounded-medium",
      lg: "rounded-lg",
      "3xl": "rounded-3xl",
      full: "rounded-full",
      none: ""
    },
    color: {
      primary: "text-color-primary",
      brand: "text-brand-600",
      danger: "text-danger-600"
    },
    shadow: {
      "blur-sm": "shadow-blur-sm"
    }
  },
  defaultVariants: {
    variant: "primary",
    size: "default",
    color: "primary",
    radius: "lg"
  }
});

type ButtonOwnProps<E extends ElementType = "button"> = {
  as?: E;
  children?: React.ReactNode;
  className?: string | undefined;
  loading?: boolean;
  loaderProps?: LoaderProps;
  leftIcon?: React.ReactNode;
  disabled?: boolean;
  rightIcon?: React.ReactNode;
  layerClassName?: string;
} & VariantProps<typeof button>;

type ButtonProps<E extends ElementType = "button"> = ButtonOwnProps<E> & ComponentPropsWithRef<E>;

type SubButtonProps<E extends ElementType = "button"> = ButtonOwnProps<E> &
  ComponentPropsWithoutRef<E>;

export const Button: <E extends ElementType = "button">(props: ButtonProps<E>) => ReactNode =
  (() => {
    // eslint-disable-next-line react/display-name
    return forwardRef((props: SubButtonProps, ref: ForwardedRef<any>) => {
      const {
        as,
        children,
        className,
        loading,
        loaderProps,
        disabled,
        leftIcon,
        rightIcon,
        layerClassName,
        variant = "primary",
        size,
        radius,
        color,
        shadow,
        ...others
      } = props;

      const Element = as || "button";

      return (
        <Element
          className={cn(
            "overflow-hidden outline-none",
            button({ variant, size, radius, color, shadow }),
            disabled && "opacity-50 cursor-not-allowed bg-black bg-opacity-70",
            className
          )}
          disabled={disabled}
          ref={ref}
          {...others}
        >
          {loading ? (
            <div
              className="absolute top-[50%] left-[50%] z-10"
              style={{ transform: "translate(-50%, calc(-50% + calc(0rem)))" }}
            >
              <Loader {...loaderProps} />
            </div>
          ) : null}

          <div
            className={cn(
              "z-1 relative flex justify-center items-center",
              loading && "opacity-0",
              layerClassName
            )}
          >
            {leftIcon && <span className="mr-2">{leftIcon}</span>}
            {children}
            {rightIcon && <span className="ml-2 font-semibold">{rightIcon}</span>}
          </div>

          <span
            className={cn(
              "absolute -top-[0px] right-[0px] bottom-[0px] left-[0px] opacity-15 blur-[10px]",
              variant === "primary" ? "block" : "hidden"
            )}
            style={{
              background:
                "conic-gradient(rgba(255, 126, 171, 0.5) 85deg, rgba(48, 131, 255, 0.4) 90deg, rgba(48, 131, 255, 0.4) 337deg, rgba(48, 131, 255, 0.4) 55deg)"
            }}
          />
        </Element>
      );
    });
  })();
