"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MentionTo = exports.MentionType = void 0;
var MentionType;
(function (MentionType) {
    MentionType["COMMENT"] = "COMMENT";
    MentionType["THREAD"] = "THREAD";
    MentionType["MESSAGE"] = "MESSAGE";
})(MentionType || (exports.MentionType = MentionType = {}));
var MentionTo;
(function (MentionTo) {
    MentionTo["MEMBER"] = "MEMBER";
    MentionTo["CHANNEL"] = "CHANNEL";
    MentionTo["USER"] = "USER";
})(MentionTo || (exports.MentionTo = MentionTo = {}));
