"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HomeSections = exports.ServerStatus = exports.AccessMode = exports.PurposeType = void 0;
var PurposeType;
(function (PurposeType) {
    PurposeType["A"] = "A";
    PurposeType["B"] = "B";
    PurposeType["C"] = "C";
    PurposeType["D"] = "D";
})(PurposeType || (exports.PurposeType = PurposeType = {}));
var AccessMode;
(function (AccessMode) {
    AccessMode["PUBLIC"] = "PUBLIC";
    AccessMode["PRIVATE"] = "PRIVATE";
    AccessMode["INVITE_ONLY"] = "INVITE_ONLY";
})(AccessMode || (exports.AccessMode = AccessMode = {}));
var ServerStatus;
(function (ServerStatus) {
    ServerStatus["ACTIVE"] = "ACTIVE";
    ServerStatus["IN_ACTIVE"] = "IN_ACTIVE";
})(ServerStatus || (exports.ServerStatus = ServerStatus = {}));
var HomeSections;
(function (HomeSections) {
    HomeSections["BasicSection"] = "BasicSection";
    HomeSections["PhotoGallery"] = "PhotoGallery";
    HomeSections["News"] = "News";
    HomeSections["Accordion"] = "Accordion";
    HomeSections["SocialLinks"] = "SocialLinks";
    HomeSections["CTA"] = "CTA";
    HomeSections["CardShowcase"] = "CardShowcase";
    HomeSections["Links"] = "Links";
    HomeSections["BannerSection"] = "BannerSection";
    HomeSections["JoinCommunity"] = "JoinCommunity";
    HomeSections["Events"] = "Events";
})(HomeSections || (exports.HomeSections = HomeSections = {}));
