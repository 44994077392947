"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConversationType = void 0;
var ConversationType;
(function (ConversationType) {
    ConversationType["CHANNEL"] = "CHANNEL";
    ConversationType["ONE_TO_ONE"] = "ONE_TO_ONE";
    ConversationType["ONE_TO_MANY"] = "ONE_TO_MANY";
    ConversationType["EVENT"] = "EVENT";
})(ConversationType || (exports.ConversationType = ConversationType = {}));
