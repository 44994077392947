"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuestionType = void 0;
var QuestionType;
(function (QuestionType) {
    QuestionType["SINGLE_SELECT"] = "SINGLE_SELECT";
    QuestionType["MULTI_SELECT"] = "MULTI_SELECT";
    QuestionType["TEXT"] = "TEXT";
    QuestionType["LINK"] = "LINK";
    QuestionType["DATE"] = "DATE";
})(QuestionType || (exports.QuestionType = QuestionType = {}));
