import { cn } from "@/lib/utils";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import React, { ElementType, ComponentPropsWithoutRef } from "react";

type TextOwnProps<E extends ElementType = ElementType> = {
  as?: E;
  children?: React.ReactNode;
  className?: string | undefined;
} & VariantProps<typeof text>;

type TextProps<E extends ElementType> = TextOwnProps<E> &
  Omit<ComponentPropsWithoutRef<E>, keyof TextOwnProps>;

export const text = cva([], {
  variants: {
    variant: {
      caption_two: "text-100 leading-100",
      caption_two_strong: "text-100 leading-100 font-semibold",

      caption_one: "text-200 leading-200",
      caption_one_strong: "text-200 leading-200 font-semibold",

      body_one: "text-300 leading-300",
      body_one_strong: "text-300 leading-300 font-semibold",

      body_two: "text-400 leading-400",
      body_two_strong: "text-400 leading-400 font-semibold",

      body_three: "text-500 leading-400",
      body_three_strong: "text-500 leading-400 font-semibold",

      subtitle_two: "text-400 leading-400",
      subtitle_two_strong: "text-400 leading-400 font-semibold",

      subtitle_one: "text-500 leading-500",
      subtitle_one_strong: "text-500 leading-500 font-semibold",

      title_three: "text-600 leading-600",
      title_two: "text-700 leading-700 font-semibold",
      title_one: "text-800 leading-800 font-semibold",
      large_title: "text-900 leading-900 font-semibold",
      display: "text-1000 leading-1000 font-semibold",

      // For Mobile. Later we may need to remove as per discuss with Designer
      mobile_body_one: "text-400 leading-400",
      mobile_body_one_strong: "text-400 leading-400 font-semibold",

      mobile_caption_one: "text-300 leading-300",
      mobile_caption_one_strong: "text-300 leading-300 font-semibold"
    },
    color: {
      primary: "text-color-primary",
      secondary: "text-color-secondary",
      tertiary: "text-color-tertiary"
    }
  },
  defaultVariants: {
    color: "primary"
    //
  }
});

export const Text = <E extends ElementType = "p">({
  as,
  children,
  variant,
  className,
  ...props
}: TextProps<E>) => {
  const Element = as || "p";

  return (
    <Element className={cn(text({ variant }), className)} {...props}>
      {children}
    </Element>
  );
};
