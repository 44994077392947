import { create } from "zustand";
import { OAuthProfile } from "@/types/next-auth";

export interface AuthStore {
  token: string | null;
  auth: OAuthProfile | null;
  userId: string | null;
  memberId: string | null;
  setToken: (token: string | null) => void;
  setAuth: (auth: OAuthProfile) => void;
  setUserId: (userId: string | null) => void;
  setMemberId: (memberId: string | null) => void;
}

export const useAuth = create<AuthStore>((set) => ({
  token: null,
  auth: null,
  userId: null,
  memberId: null,
  setToken: (token: string | null) => set({ token }),
  setAuth: (auth: OAuthProfile) => set({ auth }),
  setUserId: (userId: string | null) => set({ userId }),
  setMemberId: (memberId: string | null) => set({ memberId })
}));
