"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FavouriteType = void 0;
var FavouriteType;
(function (FavouriteType) {
    FavouriteType["CHANNEL"] = "CHANNEL";
    FavouriteType["ONE_TO_ONE"] = "ONE_TO_ONE";
    FavouriteType["ONE_TO_MANY"] = "ONE_TO_MANY";
    FavouriteType["EVENT"] = "EVENT";
})(FavouriteType || (exports.FavouriteType = FavouriteType = {}));
