"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.REQUEST_METHOD = exports.MODULE_NAME = void 0;
var MODULE_NAME;
(function (MODULE_NAME) {
    MODULE_NAME["COMMUNITY"] = "Community";
    MODULE_NAME["USER"] = "User";
    MODULE_NAME["CHAT"] = "Chat";
    MODULE_NAME["MESSAGE"] = "Message";
    MODULE_NAME["FILE"] = "File";
    MODULE_NAME["PROFILE"] = "Profile";
    MODULE_NAME["SLUG"] = "Slug";
    MODULE_NAME["STATUS"] = "Status";
    MODULE_NAME["DESCRIPTION"] = "Description";
    MODULE_NAME["BANNER"] = "Banner";
    MODULE_NAME["OTP"] = "Otp";
    MODULE_NAME["FIREBASE_TOKEN"] = "Firebase token";
    MODULE_NAME["ATTACHMENT"] = "Attachment";
    MODULE_NAME["CONVERSATION"] = "Conversation";
    MODULE_NAME["INVITITATION_CODE"] = "Invitation code";
    MODULE_NAME["ROLE"] = "Role";
    MODULE_NAME["REACTION"] = "Reaction";
    MODULE_NAME["EMAIL"] = "Email";
    MODULE_NAME["SETTINGS"] = "Settings";
    MODULE_NAME["CATEGORY"] = "Category";
    MODULE_NAME["AVATAR"] = "Avatar";
    MODULE_NAME["CHANNEL"] = "Channel";
    MODULE_NAME["MEMBER"] = "Member";
    MODULE_NAME["COMMENT"] = "Comment";
    MODULE_NAME["USER_REPORT"] = "User report";
    MODULE_NAME["THREAD"] = "Thread";
})(MODULE_NAME || (exports.MODULE_NAME = MODULE_NAME = {}));
var REQUEST_METHOD;
(function (REQUEST_METHOD) {
    REQUEST_METHOD["GET"] = "Get";
    REQUEST_METHOD["POST"] = "Created";
    REQUEST_METHOD["PATCH"] = "Updated";
    REQUEST_METHOD["DELETE"] = "Deleted";
    REQUEST_METHOD["JOINED"] = "Joined";
    REQUEST_METHOD["SENT"] = "Sent";
    REQUEST_METHOD["UPLOAD"] = "Uploaded";
    REQUEST_METHOD["DETAILS"] = "Details";
    REQUEST_METHOD["LIST"] = "List";
    REQUEST_METHOD["SIGN_OUT"] = "Sign-out";
    REQUEST_METHOD["PINNED"] = "Pinned";
    REQUEST_METHOD["UNPINNED"] = "Unpinned";
    REQUEST_METHOD["READ"] = "Read";
    REQUEST_METHOD["UNREAD"] = "Un-read";
    REQUEST_METHOD["LEAVE"] = "Leave";
    REQUEST_METHOD["ASSING"] = "Assigned";
})(REQUEST_METHOD || (exports.REQUEST_METHOD = REQUEST_METHOD = {}));
