"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PublishActionType = exports.PublishEntity = void 0;
var PublishEntity;
(function (PublishEntity) {
    PublishEntity["CHANNELS"] = "CHANNELS";
    PublishEntity["COMMENTS"] = "COMMENTS";
    PublishEntity["THREAD"] = "THREAD";
    PublishEntity["MEMBER"] = "MEMBER";
    PublishEntity["MESSAGE"] = "MESSAGE";
    PublishEntity["REACTIONS"] = "REACTIONS";
    PublishEntity["NOTIFICATIONS"] = "NOTIFICATIONS";
    PublishEntity["USER"] = "USER";
})(PublishEntity || (exports.PublishEntity = PublishEntity = {}));
var PublishActionType;
(function (PublishActionType) {
    PublishActionType["PRIVATE_REMOVE_MEMBER"] = "PRIVATE_REMOVE_MEMBER";
    PublishActionType["CHANNEL_ADD_MEMBER"] = "CHANNEL_ADD_MEMBER";
    PublishActionType["CHANNEL_UPDATE"] = "CHANNEL_UPDATE";
    PublishActionType["ADD_COMMENT"] = "ADD_COMMENT";
    PublishActionType["UPDATE_COMMENT"] = "UPDATE_COMMENT";
    PublishActionType["DELETE_COMMENT"] = "DELETE_COMMENT";
    PublishActionType["PRIVATE_ADD_MEMBER"] = "PRIVATE_ADD_MEMBER";
    PublishActionType["CHAT_DELETE_MESSAGE"] = "CHAT_DELETE_MESSAGE";
    PublishActionType["UPDATE"] = "UPDATE";
    PublishActionType["CHAT_MESSAGE_UPDATE"] = "CHAT_MESSAGE_UPDATE";
    PublishActionType["CHANNEL_UPDATE_MEMBER"] = "CHANNEL_UPDATE_MEMBER";
    PublishActionType["PRIVATE_UPDATE_MEMBER"] = "PRIVATE_UPDATE_MEMBER";
    PublishActionType["CHANNEL_REMOVE_MEMBER"] = "CHANNEL_REMOVE_MEMBER";
    PublishActionType["UPDATE_MEMBER"] = "UPDATE_MEMBER";
    PublishActionType["UPDATE_PINNED"] = "UPDATE_PINNED";
    PublishActionType["REACTIONS"] = "REACTIONS";
    PublishActionType["REACTIONS_MESSAGES"] = "REACTIONS_MESSAGES";
    PublishActionType["THREAD_CREATE"] = "THREAD_CREATE";
    PublishActionType["THREAD_UPDATE"] = "THREAD_UPDATE";
    PublishActionType["THREAD_DELETE"] = "THREAD_DELETE";
    PublishActionType["CREATE"] = "CREATE";
    PublishActionType["USER_UPDATE"] = "UPDATE_USER";
})(PublishActionType || (exports.PublishActionType = PublishActionType = {}));
