import { cn } from "@/lib/utils";
import * as React from "react";
import { Text } from "@/components/ui/text";

export interface LabelProp extends React.InputHTMLAttributes<HTMLLabelElement> {
  children?: React.ReactNode;
  className?: string;
}

const Label: React.FC<LabelProp> = ({ className, children }) => {
  return (
    <Text variant="body_one" as="label" className={cn("text-color-secondary", className)}>
      {children}
    </Text>
  );
};

export default Label;
