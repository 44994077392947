"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApprovalMode = exports.InviteCodeType = void 0;
var InviteCodeType;
(function (InviteCodeType) {
    InviteCodeType["PUBLIC"] = "PUBLIC";
    InviteCodeType["PRIVATE"] = "PRIVATE";
})(InviteCodeType || (exports.InviteCodeType = InviteCodeType = {}));
var ApprovalMode;
(function (ApprovalMode) {
    ApprovalMode["AUTO"] = "AUTO";
    ApprovalMode["MANUAL"] = "MANUAL";
})(ApprovalMode || (exports.ApprovalMode = ApprovalMode = {}));
